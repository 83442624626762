export const Dashboard = () => {
  return (
    <div
      className={`calculator-wrapper ${
        window.self === window.top ? "mt-5" : ""
      }`}
    >
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center py-2">
            <h2 className="card-title">Calculator</h2>
          </div>
        </div>
        <div className="card-body pt-4 pb-4">
          <div className="calculator-selector mb-3">
            <a href="/emi" className="calculator-select-btn btn-emi">
              Borrowing
            </a>
          </div>
          <div className="calculator-selector mb-3">
            <a href="/sip" className="calculator-select-btn btn-sip">
              Investment
            </a>
          </div>
          <div className="calculator-selector mb-3">
            <a href="/lumpsum" className="calculator-select-btn btn-lump">
              Annuity
            </a>
          </div>
          <div className="calculator-selector">
            <a href="/income-tax" className="calculator-select-btn btn-it">
              Income Tax
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
